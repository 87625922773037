import { useAuthStore } from "@/stores/auth";
import { supabase } from "@/lib/supabaseClient";

export const apiFetch = async (endpoint, options = {}) => {
  const authStore = useAuthStore();
  let token = authStore.user?.session?.access_token;

  if (!token) {
    const {
      data: { session },
    } = await supabase.auth.getSession();

    token = session.access_token;
  }

  const response = await fetch(endpoint, {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  }

  return data;
};

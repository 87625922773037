/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "@/App.vue";

// Composables
import { createApp } from "vue";
import { createPinia } from "pinia";

// Import Supabase client
import { supabase } from "@/lib/supabaseClient";

// Import router and vuetify
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import posthog from "@/plugins/posthog";
// Import the createAppLogger function
import { createAppLogger } from "@/utils/logger";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

// Create the logger
const { logger, logtail } = createAppLogger();

Sentry.init({
  app,
  dsn: "https://4458570cd5efe818a0258abef2d85759@o86820.ingest.us.sentry.io/4508082178621440",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/staywith.jackandtrever.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Set the default title
document.title = "Stay with Jack & Trever";

// Make Supabase available globally
app.config.globalProperties.$supabase = supabase;

// Make logger available globally
app.config.globalProperties.$logger = logger;

app.use(createPinia());
app.use(router);
app.use(vuetify);
app.use(posthog);

// Replace error and warn handlers
app.config.errorHandler = (err, vm, info) => {
  logger.error('Unhandled error', { err, info });
};

app.config.warnHandler = (msg, vm, trace) => {
  logger.warn('Vue warning', { msg, trace });
};

app.mount("#app");

// Ensure logs are sent before the page unloads
window.addEventListener('beforeunload', () => {
  logtail.flush();
});

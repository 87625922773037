<template>
  <v-app>
    <v-app-bar app flat>
      <v-app-bar-title class="d-flex align-center" style="max-width: 100%">
        <v-btn
          class="custom-title pa-0"
          flat
          :ripple="false"
          variant="plain"
          to="/"
          text
        >
          Stay with Jack & Trever
        </v-btn>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="authStore.isAuthenticated && !isMobile"
        color="primary"
        @click="openBookingDrawer"
        class="mr-2"
      >
        Book a New Stay
      </v-btn>
      <v-menu v-if="authStore.isAuthenticated" rounded>
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" size="40" class="mr-4">
            <v-avatar color="primary" size="40">
              <v-img
                v-if="authStore.user.avatar_url"
                :src="authStore.user.avatar_url"
              ></v-img>
              <span v-else>{{ authStore.user.first_name?.charAt(0) }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="isMobile" @click="openBookingDrawer">
            <v-list-item-title>Book a New Stay</v-list-item-title>
          </v-list-item>
          <v-list-item to="/profile">
            <v-list-item-title>Edit Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="authStore.signOut">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="fixed-height">
      <router-view></router-view>
    </v-main>

    <v-navigation-drawer
      v-model="bookingDrawer"
      temporary
      location="right"
      width="600"
      height="100%"
    >
      <CreateBooking @close="bookingDrawer = false" />
    </v-navigation-drawer>

    <!-- Updated snackbar component -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      absolute
      location="top right"
      variant="tonal"
    >
      <div class="d-flex align-center">
        <v-icon :icon="snackbar.icon" class="mr-3" />
        {{ snackbar.text }}
      </div>

      <template v-slot:actions>
        <v-btn text @click="snackbar.show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup>
import { ref, onMounted, provide, computed, watch } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useBookingsStore } from "@/stores/bookings";
import { useRouter } from "vue-router";
import CreateBooking from "@/components/CreateBooking.vue";
import { useSnackbar } from "@/composables/useSnackbar";
import { useDisplay } from "vuetify";

const authStore = useAuthStore();
const bookingsStore = useBookingsStore();
const router = useRouter();
const bookingDrawer = ref(false);
const { mobile } = useDisplay();

const isMobile = computed(() => mobile.value);

const snackbar = ref({
  show: false,
  text: "",
  color: "success",
  timeout: 3000,
  icon: "mdi-check-circle",
});

const showSnackbar = (text, type = "info", timeout = 3000) => {
  const snackbarConfig = getSnackbarConfig(type);
  snackbar.value = {
    show: true,
    text,
    color: snackbarConfig.color,
    icon: snackbarConfig.icon,
    timeout,
  };
};

const getSnackbarConfig = (type) => {
  switch (type) {
    case "success":
      return { color: "success", icon: "mdi-check-circle" };
    case "error":
      return { color: "error", icon: "mdi-alert-circle" };
    case "warning":
      return { color: "warning", icon: "mdi-alert" };
    case "info":
    default:
      return { color: "info", icon: "mdi-information" };
  }
};

// Provide the showSnackbar function
provide("showSnackbar", showSnackbar);

const openBookingDrawer = async () => {
  bookingDrawer.value = true;
  await bookingsStore.fetchAvailableDates();
};

const closeBookingDrawer = () => {
  bookingDrawer.value = false;
};

// Provide the necessary functions and reactive variables
provide("openBookingDrawer", openBookingDrawer);
provide("closeBookingDrawer", closeBookingDrawer);
provide("isBookingDrawerOpen", bookingDrawer);
provide("showSnackbar", showSnackbar);

onMounted(async () => {
  await authStore.fetchUser();
  if (
    !authStore.isAuthenticated &&
    router.currentRoute.value.meta.requiresAuth
  ) {
    router.push("/login");
  }
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Inter:wght@400;500&display=swap");

.v-application {
  font-family: "Inter", sans-serif;
}

.custom-title {
  font-family: "Playfair Display", serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  letter-spacing: 0.5px;
  opacity: 1;
  white-space: nowrap;
}

.v-app-bar-title {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn {
  text-transform: none;
  font-weight: 500;
}

@media (max-width: 600px) {
  .v-app-bar-title {
    max-width: 50px; /* Adjust as needed */
  }
}

.fixed-height {
  height: calc(
    100vh - var(--v-layout-top)
  ); /* Adjust 64px if your app-bar height is different */
  overflow: hidden;
}
</style>

<template>
  <div class="create-booking">
    <v-toolbar class="sticky-header" height="64">
      <v-toolbar-title>Book a Stay</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-stepper
      v-model="currentStep"
      alt-labels
      class="stepper-container"
      :mobile="isMobile"
    >
      <v-stepper-header class="stepper-header d-block">
        <div class="v-stepper-header" style="box-shadow: none">
          <template v-for="(item, i) in stepItems" :key="i">
            <v-stepper-item
              :value="item.value"
              :title="item.title"
              :complete="currentStep > item.value"
            ></v-stepper-item>
            <v-divider v-if="i !== stepItems.length - 1"></v-divider>
          </template>
        </div>
        <div
          class="stepper-header-summary px-4 py-2"
          v-html="getStepSummary(currentStep)"
        ></div>
      </v-stepper-header>

      <v-stepper-window class="stepper-window">
        <v-stepper-window-item :value="1">
          <ChooseRoomStep
            :selectedRoom="selectedRoom"
            @update:selectedRoom="updateSelectedRoom"
          />
        </v-stepper-window-item>

        <v-stepper-window-item :value="2">
          <SelectDatesStep
            :dates="dates"
            :selectedRoom="selectedRoom"
            :allowedDates="allowedDates"
            @update:dates="updateDates"
          />
        </v-stepper-window-item>

        <v-stepper-window-item :value="3">
          <PlusOneDetailsStep
            :selectedRoom="selectedRoom.value"
            :hasPlusOne="hasPlusOne"
            :plusOne="plusOne"
            @update:hasPlusOne="updateHasPlusOne"
            @update:plusOne="updatePlusOne"
          />
        </v-stepper-window-item>

        <v-stepper-window-item :value="4">
          <FinalDetailsStep
            :user-email="authStore.user?.email"
            @update:email="updateEmail"
            @update:dietaryRestrictions="updateDietaryRestrictions"
          />
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>

    <div class="sticky-footer">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-btn
              @click="prevStep"
              color="secondary"
              :disabled="currentStep === 1 || isLoading"
              block
            >
              Back
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              v-if="currentStep < 4"
              @click="nextStep"
              color="primary"
              :disabled="!canProceed"
              block
            >
              Next
            </v-btn>
            <v-btn
              v-else
              @click="createBooking"
              color="success"
              :loading="isLoading"
              :disabled="isLoading"
              block
            >
              Book Stay
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits, getCurrentInstance } from "vue";
import { useBookingsStore } from "@/stores/bookings";
import { useAuthStore } from "@/stores/auth";
import { inject } from "vue";
import { apiFetch } from "@/utils/api";
import ChooseRoomStep from "@/components/steps/ChooseRoomStep.vue";
import SelectDatesStep from "@/components/steps/SelectDatesStep.vue";
import PlusOneDetailsStep from "@/components/steps/PlusOneDetailsStep.vue";
import FinalDetailsStep from "@/components/steps/FinalDetailsStep.vue";
import dayjs from "dayjs";
import confetti from "canvas-confetti";
import { useDisplay } from "vuetify";

const { proxy } = getCurrentInstance();
const logger = proxy.$logger;

const emit = defineEmits(["close"]);

const bookingsStore = useBookingsStore();
const authStore = useAuthStore();

const currentStep = ref(1);
const dates = ref([]);
const selectedRoom = ref(null);
const dietaryRestrictions = ref("");
const hasPlusOne = ref(false);
const plusOne = ref({
  firstName: "",
  lastName: "",
  phoneNumber: "",
  bedSide: "",
  email: "",
});
const email = ref("");

let allowedDates = ref([]);

const { mobile } = useDisplay();
const isMobile = computed(() => mobile.value);

const stepItems = [
  { title: "Choose Room", value: 1 },
  { title: "Select Dates", value: 2 },
  { title: "Plus One Details", value: 3 },
  { title: "Final Details", value: 4 },
];

const canProceed = computed(() => {
  switch (currentStep.value) {
    case 1:
      return !!selectedRoom.value;
    case 2:
      return dates.value.length > 1;
    case 3:
      return (
        !hasPlusOne.value ||
        (plusOne.value.firstName &&
          plusOne.value.lastName &&
          plusOne.value.phoneNumber)
      );
    default:
      return true;
  }
});

const updateSelectedRoom = (room) => {
  //   console.log("updateSelectedRoom", room);
  selectedRoom.value = room;
  updateAllowedDates();
};

const updateDates = (newDates) => {
  dates.value = newDates;
};

const updateHasPlusOne = (value) => {
  hasPlusOne.value = value;
};

const updatePlusOne = (value) => {
  plusOne.value = value;
};

const updateDietaryRestrictions = (value) => {
  dietaryRestrictions.value = value;
};

const updateAllowedDates = () => {
  // logger.info('Updating allowed dates', { room: selectedRoom.value });
  dates.value = []; // Reset selected dates when room changes
  //   console.log("selectedRoomDD", selectedRoom.value);
  if (
    selectedRoom.value &&
    bookingsStore.availableDates[selectedRoom.value.value]
  ) {
    allowedDates.value = bookingsStore.availableDates[selectedRoom.value.value];
  } else {
    allowedDates.value = [];
  }
};

watch(
  () => bookingsStore.availableDates,
  () => {
    updateAllowedDates();
  },
  { deep: true }
);

const nextStep = () => {
  if (canProceed.value) {
    currentStep.value++;
  }
};

const prevStep = () => {
  currentStep.value--;
};

const updateEmail = (newEmail) => {
  email.value = newEmail;
};

const isLoading = ref(false);

const createBooking = async () => {
  isLoading.value = true;
  try {
    logger.info('Creating new booking', { room: selectedRoom.value, dates: dates.value });
    const guestId = authStore.user.id;
    const roomId = selectedRoom.value.value;
    const checkInDate = dayjs(
      Math.min(...dates.value.map((d) => new Date(d)))
    ).format("YYYY-MM-DD");
    const checkOutDate = dayjs(
      Math.max(...dates.value.map((d) => new Date(d)))
    ).format("YYYY-MM-DD");

    const data = await apiFetch("/api/createBooking", {
      method: "POST",
      body: JSON.stringify({
        guestId,
        roomId,
        checkInDate,
        checkOutDate,
        dietaryRestrictions: dietaryRestrictions.value,
        plusOne: hasPlusOne.value ? plusOne.value : null,
        email: email.value,
      }),
    });

    // Trigger confetti with high z-index
    const myConfetti = confetti.create(null, {
      resize: true,
      useWorker: true,
    });
    myConfetti({
      particleCount: 300,
      spread: 90,
      origin: { y: 0.6 },
      zIndex: 10000, // Set a high z-index
    });
    isLoading.value = false;
    showSnackbar("Booking created successfully!", "success");

    // Close the booking drawer
    emit("close");

    // Refresh the page after a short delay
    setTimeout(() => {
      window.location.reload();
    }, 1000); // Adjust the delay as needed
  } catch (error) {
    logger.error('Error creating booking', { err: error });
    console.error("Error creating booking:", error);
    showSnackbar("Error creating booking. Please try again.", "error");
  }
};

const showSnackbar = inject("showSnackbar");

const getStepSummary = (step) => {
  let summary = "";

  if (step >= 1 && selectedRoom.value) {
    summary += `You're staying in the <b>${selectedRoom.value.name}</b>`;
  }

  if (step >= 2 && dates.value.length > 0) {
    const minDate = dayjs(Math.min(...dates.value.map((d) => dayjs(d))));
    const maxDate = dayjs(Math.max(...dates.value.map((d) => dayjs(d))));

    const formattedMinDate = minDate.format("M/D");
    const formattedMaxDate = maxDate.format("M/D");

    const nights =
      maxDate.diff(minDate, "day") === 0 ? 1 : maxDate.diff(minDate, "day");
    const nightText = nights === 1 ? "night" : "nights";
    summary += ` for <b>${nights} ${nightText} (${formattedMinDate} - ${formattedMaxDate})</b>`;
  }

  if (step >= 3 && hasPlusOne.value && plusOne.value.firstName) {
    summary += ` with <b>${plusOne.value.firstName}</b>`;
  }

  return summary || "Start by selecting a room";
};
</script>

<style scoped>
.create-booking {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.sticky-header {
  flex-shrink: 0;
}

.stepper-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.stepper-header {
  flex-shrink: 0;
  height: 72px; /* Adjust as needed */
}

.stepper-window {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  margin: 0;
}

.sticky-footer {
  flex-shrink: 0;
  height: 80px; /* Adjust as needed */
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.stepper-header-summary {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  background-color: #f5f5f5;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

@media (max-width: 600px) {
  :deep(.v-stepper-item) {
    padding-bottom: 0 !important;
  }
}
</style>
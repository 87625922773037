/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Composables
import { createVuetify } from "vuetify";

const shadcnTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    // "surface-variant": "#F1F5F9",
    "surface-variant": "#d6ebff",
    "on-surface-variant": "#0F172A",
    primary: "#1E293B",
    "primary-darken-1": "#1E293B",
    secondary: "#F1F5F9",
    "secondary-darken-1": "#F1F5F9",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
    card: "#FFFFFF",
    "card-foreground": "#0F172A",
    popover: "#FFFFFF",
    "popover-foreground": "#0F172A",
    "primary-foreground": "#F8FAFC",
    "secondary-foreground": "#1E293B",
    muted: "#F1F5F9",
    "muted-foreground": "#64748B",
    accent: "#F1F5F9",
    "accent-foreground": "#1E293B",
    destructive: "#EF4444",
    "destructive-foreground": "#F8FAFC",
    border: "#E2E8F0",
    input: "#E2E8F0",
    ring: "#0F172A",
    "chart-1": "#F97316",
    "chart-2": "#14B8A6",
    "chart-3": "#0F766E",
    "chart-4": "#EAB308",
    "chart-5": "#F97316",
  },
  // ... rest of the theme configuration ...
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  defaults: {
    VTextField: {
      variant: "outlined",
    },
    global: {
      ripple: false,
      flat: true,
    },
  },
  theme: {
    defaultTheme: "shadcnTheme",
    themes: {
      shadcnTheme,
    },
  },
});

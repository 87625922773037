import { defineStore } from "pinia";
import { supabase } from "@/lib/supabaseClient.js";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.user,
  },
  actions: {
    async fetchUser() {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (user) {
        const { data: profile } = await supabase
          .from("authorized_guests")
          .select("*")
          .single();

        this.user = {
          ...user,
          ...profile,
          authorized_guest_id: profile.id,
          session,
        };
      } else {
        this.user = null;
      }
    },
    async signOut() {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      this.user = null;
      window.location.reload();
    },
    async updateProfile(profileData) {
      // Filter profileData to only include valid columns
      const validColumns = [
        "first_name",
        "last_name",
        "phone_number",
        "email",
        "dietary_restrictions",
        "bedTime",
        "wakeupTime",
        "initialSleepLevel",
        "midStageSleepLevel",
        "finalSleepLevel",
      ];
      const filteredProfileData = Object.keys(profileData)
        .filter((key) => validColumns.includes(key))
        .reduce((obj, key) => {
          obj[key] = profileData[key];
          return obj;
        }, {});

      const { data, error } = await supabase
        .from("authorized_guests")
        .update(filteredProfileData)
        .eq("id", this.user.authorized_guest_id);

      if (error) throw error;

      // Update auth user phone number if it has changed
      if (profileData.phone_number && profileData.phone_number !== this.user.phone_number) {
        const { error: updatePhoneError } = await supabase.auth.updateUser({
          phone: profileData.phone_number,
        });

        if (updatePhoneError) throw updatePhoneError;
      }

      await this.fetchUser();
    },
    async uploadAvatar(file) {
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${this.user.id}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data } = supabase.storage.from("avatars").getPublicUrl(filePath);

      await this.updateProfile({ avatar_url: data.publicUrl });
    },
    updateUser(userData) {
      this.user = {
        ...this.user,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone_number: userData.phone_number,
        email: userData.email,
        avatar_url: userData.avatar_url,
        dietary_restrictions: userData.dietary_restrictions,
      };
    },
  },
});

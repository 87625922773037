import { defineStore } from "pinia";

const CDN_URL = import.meta.env.VITE_SUPABASE_CDN_URL;

export const useRoomsStore = defineStore("rooms", {
  state: () => ({
    rooms: {
      red: {
        name: "Red Room",
        images: [
          `${CDN_URL}/red/room1.png`,
          `${CDN_URL}/red/room2.png`,
        ],
        benefits: [
          { icon: "mdi-bed-queen", description: "Queen-sized bed" },
          { icon: "mdi-shower", description: "Private bathroom with Aesop products" },
          { icon: "mdi-tree", description: "Pond view" },
          { icon: "mdi-food-croissant", description: "Complimentary breakfast" },
          { icon: "mdi-desk", description: "Large working desk" },
          { icon: "mdi-usb-port", description: "Multiple USB-C chargers" },
          { icon: "mdi-wifi", description: "High-speed Wi-Fi" },
        ],
      },
      green: {
        name: "Green Room",
        images: [
          `${CDN_URL}/green/room1.png`,
          `${CDN_URL}/green/room2.png`,
        ],
        benefits: [
          { icon: "mdi-bed-king", description: "King-sized bed with Eight Sleep Pod 4" },
          { icon: "mdi-shower", description: "En-suite bathroom with Aesop products" },
          { icon: "mdi-tree", description: "Pond view" },
          { icon: "mdi-food", description: "Complimentary breakfast" },
          { icon: "mdi-desk", description: "Large working desk" },
          { icon: "mdi-usb-port", description: "Multiple USB-C chargers" },
          { icon: "mdi-wifi", description: "High-speed Wi-Fi" },
          { icon: "mdi-television", description: "55-inch 4K Smart TV" },
        ],
      },
    },
  }),
  getters: {
    getRoomInfo: (state) => (roomColor) => state.rooms[roomColor],
  },
});

<template>
  <v-card variant="outlined">
    <v-card-title>Plus One Details</v-card-title>
    <v-checkbox
      v-model="hasPlusOne"
      label="Do you have a plus one?"
      hide-details
      class="mt-2 ml-2"
    ></v-checkbox>
    <v-card v-if="hasPlusOne" class="mt-0 border-0">
      <v-card-text>
        <v-select
          v-if="historicalPlusOnes.length > 0"
          v-model="selectedHistoricalPlusOne"
          :items="historicalPlusOnes"
          item-title="first_name"
          item-value="id"
          label="Select a previous plus one"
          @update:modelValue="updatePlusOneFromHistorical"
        >
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="props">
              <v-list-item-title
                >{{ item.raw.first_name }}
                {{ item.raw.last_name }}</v-list-item-title
              >
              <v-list-item-subtitle>{{
                item.raw.phone_number
              }}</v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-select>
        <v-row>
          <v-col cols="12" md="6" class="pb-xs-0 pb-sm-0">
            <v-text-field
              v-model="plusOne.firstName"
              label="First Name"
              hide-details
              required
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="plusOne.lastName"
              hide-details
              label="Last Name (Optional)"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="plusOne.phoneNumber"
              label="Phone Number"
              placeholder="XXX-XXX-XXXX"
              hide-details
              required
              type="tel"
              @input="formatPhoneNumber"
              :rules="[rules.required, rules.phoneFormat]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="selectedRoom === 'green'">
            <v-card-subtitle
              >Which side of the bed does your plus one sleep
              on?</v-card-subtitle
            >
            <v-chip-group
              v-model="plusOne.bedSide"
              mandatory
              selected-class="primary"
            >
              <v-chip
                v-for="side in ['Left', 'Right']"
                :key="side"
                :value="side"
                filter
                variant="outlined"
                class="bed-side-chip"
              >
                <v-icon start>{{
                  side === "Left" ? "mdi-arrow-left" : "mdi-arrow-right"
                }}</v-icon>
                {{ side }}
              </v-chip>
            </v-chip-group>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="plusOne.email"
              label="Email Address (Optional)"
              hide-details
              type="email"
              :rules="[rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, onMounted } from "vue";
import { usePlusOnesStore } from "@/stores/plusOnes";

const emit = defineEmits(["update:hasPlusOne", "update:plusOne"]);
const props = defineProps({
  selectedRoom: {
    type: String,
    required: true,
  },
});

const plusOnesStore = usePlusOnesStore();
const historicalPlusOnes = ref([]);
const selectedHistoricalPlusOne = ref(null);

const hasPlusOne = ref(false);
const plusOne = ref({
  firstName: "",
  lastName: "",
  phoneNumber: "",
  bedSide: "Left", // Default value
  email: "", // Add this line
  dietaryRestrictions: "",
});

const rules = {
  required: (value) => !!value || "Required.",
  phoneFormat: (value) =>
    /^\d{3}-\d{3}-\d{4}$/.test(value) ||
    "Phone number must be in XXX-XXX-XXXX format",
  email: (value) => {
    if (!value) return true; // Optional field
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(value) || "Invalid e-mail.";
  },
};

const formatPhoneNumber = () => {
  // Remove all non-digit characters
  let cleaned = plusOne.value.phoneNumber.replace(/\D/g, "");

  // Format the number as XXX-XXX-XXXX
  if (cleaned.length > 0) {
    if (cleaned.length <= 3) {
      plusOne.value.phoneNumber = cleaned;
    } else if (cleaned.length <= 6) {
      plusOne.value.phoneNumber = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else {
      plusOne.value.phoneNumber = `${cleaned.slice(0, 3)}-${cleaned.slice(
        3,
        6
      )}-${cleaned.slice(6, 10)}`;
    }
  } else {
    plusOne.value.phoneNumber = "";
  }
};

const updatePlusOneFromHistorical = () => {
  const selected = historicalPlusOnes.value.find(
    (po) => po.id === selectedHistoricalPlusOne.value
  );
  if (selected) {
    plusOne.value.firstName = selected.first_name;
    plusOne.value.lastName = selected.last_name;
    plusOne.value.phoneNumber = selected.phone_number;
  }
};

onMounted(async () => {
  await plusOnesStore.fetchHistoricalPlusOnes();
  historicalPlusOnes.value = plusOnesStore.historicalPlusOnes;
});

watch(hasPlusOne, (value) => {
  emit("update:hasPlusOne", value);
});

watch(
  plusOne,
  (value) => {
    emit("update:plusOne", value);
  },
  { deep: true }
);
</script>

<style scoped>
.v-card {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.bed-side-chip {
  border-radius: 4px !important;
}
</style>

import { Logtail } from "@logtail/browser";

export function createAppLogger() {
  const logtail = new Logtail(import.meta.env.VITE_LOGTAIL_SOURCE_TOKEN);

  const logger = {
    info: (message, data) => logtail.info(message, data),
    warn: (message, data) => logtail.warn(message, data),
    error: (message, data) => logtail.error(message, data),
    debug: (message, data) => logtail.debug(message, data),
  };

  return { logger, logtail };
}
<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-3">
          <v-card-title class="text-h5 font-weight-bold justify-center py-6">
            Sign In
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="handleSubmit">
              <v-slide-x-transition>
                <v-text-field
                  v-if="!otpSent"
                  v-model="phone"
                  label="Phone Number"
                  placeholder="XXX-XXX-XXXX"
                  required
                  type="tel"
                  @input="formatPhoneNumber"
                  :rules="[rules.required, rules.phoneFormat]"
                  prepend-inner-icon="mdi-phone"
                ></v-text-field>
              </v-slide-x-transition>
              <v-slide-x-transition>
                <v-otp-input
                  v-if="otpSent"
                  v-model="otp"
                  length="6"
                  type="number"
                  :rules="[rules.required, rules.otpFormat]"
                  ref="otpInput"
                ></v-otp-input>
              </v-slide-x-transition>
              
              <!-- Turnstile widget -->
              <vue-turnstile site-key="0x4AAAAAAAk0GGwsJ8AhfYLI" appearance="interaction-only" v-model="turnstileToken" />
 
              <v-btn
                type="submit"
                color="primary"
                block
                class="mt-4"
                :loading="loading"
                :disabled="!turnstileToken"
              >
                {{ otpSent ? "Verify OTP" : "Send OTP" }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, nextTick, inject, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { supabase } from "@/lib/supabaseClient";
import VueTurnstile from 'vue-turnstile';

const authStore = useAuthStore();
const router = useRouter();
const phone = ref("");
const otp = ref("");
const otpSent = ref(false);
const loading = ref(false);
const otpInput = ref(null);
const turnstileToken = ref(null);
const idempotencyKey = ref(null);

// Inject the global showSnackbar function
const showSnackbar = inject("showSnackbar");

const rules = {
  required: (value) => !!value || "Required.",
  phoneFormat: (value) =>
    /^\d{3}-\d{3}-\d{4}$/.test(value) ||
    "Phone number must be in XXX-XXX-XXXX format",
  otpFormat: (value) => value.length === 6 || "OTP must be 6 digits",
};

const formatPhoneNumber = () => {
  // Remove all non-digit characters
  let cleaned = phone.value.replace(/\D/g, '');
  
  // Limit to 10 digits
  cleaned = cleaned.substring(0, 10);
  
  // Format the number
  let formatted = '';
  if (cleaned.length > 0) {
    formatted += cleaned.substring(0, 3);
    if (cleaned.length > 3) {
      formatted += '-' + cleaned.substring(3, 6);
      if (cleaned.length > 6) {
        formatted += '-' + cleaned.substring(6, 10);
      }
    }
  }
  
  // Update the phone value
  phone.value = formatted;
};

const handleSubmit = async () => {
  if (!turnstileToken.value) {
    showSnackbar("Please complete the Turnstile challenge", "error");
    return;
  }

  if (!otpSent.value) {
    await verifyAndSendOTP();
  } else {
    await verifyOTP();
  }
};

const verifyAndSendOTP = async () => {
  loading.value = true;
  try {
    const formattedPhone = "1" + phone.value.replace(/\D/g, "");
    
    // First, verify if the phone number is authorized
    const verifyResponse = await fetch('/api/verifyAuthorizedGuest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: formattedPhone,
      }),
    });

    const verifyData = await verifyResponse.json();

    if (!verifyResponse.ok) {
      throw new Error(verifyData.error || 'Failed to verify phone number');
    }

    // If verified, proceed with sending OTP
    const { error } = await supabase.auth.signInWithOtp({
      phone: "+" + formattedPhone, // Add "+" for Supabase phone auth
      options: {
        captchaToken: turnstileToken.value
      }
    });
    if (error) throw error;
    otpSent.value = true;
    showSnackbar(
      "OTP sent to your phone. Please check and enter the code.",
      "success"
    );
    nextTick(() => {
      otpInput.value.$el.querySelector("input").focus();
    });
  } catch (error) {
    showSnackbar(error.message, "error");
  } finally {
    loading.value = false;
  }
};

const verifyOTP = async () => {
  loading.value = true;
  try {
    const formattedPhone = "1" + phone.value.replace(/\D/g, "");
    const { data, error } = await supabase.auth.verifyOtp({
      phone: "+" + formattedPhone, // Add "+" for Supabase phone auth
      token: otp.value,
      type: "sms",
    });
    if (error) throw error;
    await authStore.fetchUser();
    if (authStore.user) {
      router.push("/");
    }
  } catch (error) {
    showSnackbar(error.message, "error");
  } finally {
    loading.value = false;
  }
};

// Add Turnstile callback function
const onTurnstileSuccess = (token) => {
  turnstileToken.value = token;
};

onMounted(() => {
  // Add the Turnstile callback to the window object
  window.onTurnstileSuccess = onTurnstileSuccess;
});
</script>

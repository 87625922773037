import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export function formatDate(dateString) {
  return dayjs(dateString).tz("America/New_York").format("ddd, MMM Do");
}

export function calculateNights(startDate, endDate) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return end.diff(start, "day");
}

export function formatTime(date) {
  return dayjs(date).add(4, "hours").tz("America/New_York").format("h:mm A");
}

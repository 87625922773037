<template>
  <v-card flat class="choose-room-step pa-0">
    <v-row>
      <v-col v-for="room in rooms" :key="room.value" cols="12">
        <v-card
          variant="outlined"
          :ripple="false"
          :class="[
            'room-card',
            { 'room-card--selected': selectedRoom?.value === room.value },
          ]"
          @click="selectRoom(room)"
          :elevation="selectedRoom?.value === room.value ? 2 : 0"
        >
          <v-card-title>{{ room.title }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-carousel
                  hide-delimiters
                  height="150"
                  :show-arrows="false"
                  :continuous="true"
                  :cycle="true"
                  interval="5000"
                >
                  <v-carousel-item
                    v-for="(image, index) in roomDetails[room.value].images"
                    :key="index"
                  >
                    <v-img :src="image" height="150" cover>
                      <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col cols="8">
                <v-list density="compact">
                  <v-list-item
                    v-for="(feature, index) in roomDetails[room.value].features"
                    :key="index"
                    class="pa-0"
                    style="min-height: 30px"
                  >
                    <template v-slot:prepend>
                      <v-icon
                        :icon="feature.icon"
                        color="primary"
                        size="small"
                        class="mr-0"
                      ></v-icon>
                    </template>
                    <v-list-item-title
                      v-html="feature.description"
                      class="text-body-2"
                    ></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps } from "vue";
import { useRoomsStore } from "@/stores/rooms";

const props = defineProps({
  selectedRoom: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["update:selectedRoom"]);

const roomsStore = useRoomsStore();

const rooms = [
  { title: "Red Room (Queen Bed)", value: "red", name: "Red Room" },
  { title: "Green Room (King Bed)", value: "green", name: "Green Room" },
];

const roomDetails = computed(() => ({
  red: {
    features: roomsStore.getRoomInfo("red").benefits,
    images: roomsStore.getRoomInfo("red").images,
  },
  green: {
    features: roomsStore.getRoomInfo("green").benefits,
    images: roomsStore.getRoomInfo("green").images,
  },
}));

const selectRoom = (room) => {
  emit("update:selectedRoom", room);
};
</script>

<style scoped>
.choose-room-step {
  padding: 16px;
}

.room-card {
  transition: box-shadow 0.3s ease-in-out;
}

.room-card--selected,
.room-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

/* Reduce spacing between feature lines */
.v-list-item {
  min-height: 10px;
}
</style>

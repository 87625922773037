<template>
  <v-card>
    <v-card-title>Final Details</v-card-title>
    <v-card-text>
      <v-text-field
        v-if="!userEmail"
        v-model="email"
        label="Email Address"
        hide-details
        type="email"
        :rules="[rules.required, rules.email]"
      ></v-text-field>
      <DietaryRestrictionsInput
        v-model="dietaryRestrictions"
        @update:modelValue="updateDietaryRestrictions"
        :label="dietaryRestrictionsLabel"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import DietaryRestrictionsInput from "@/components/DietaryRestrictionsInput.vue";

const authStore = useAuthStore();

const props = defineProps({
  userEmail: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:email", "update:dietaryRestrictions"]);

const email = ref(props.userEmail || "");
const dietaryRestrictions = ref(
  authStore.user?.dietary_restrictions || {"selected":[],"other":""}
);

const dietaryRestrictionsLabel = computed(() => {
  return authStore.user?.dietary_restrictions
    ? "Confirm your dietary restrictions"
    : "Dietary Restrictions";
});

const rules = {
  required: (value) => !!value || "Required.",
  email: (value) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(value) || "Invalid e-mail.";
  },
};

const updateDietaryRestrictions = (value) => {
  emit("update:dietaryRestrictions", value);
};

watch(email, (newEmail) => {
  emit("update:email", newEmail);
});

// Emit initial values
emit("update:email", email.value);
emit("update:dietaryRestrictions", dietaryRestrictions.value);
</script>

<template>
  <v-card flat>
    <v-skeleton-loader
      v-if="isLoading"
      type="date-picker"
      height="400"
    ></v-skeleton-loader>
    <v-date-picker
      v-else
      v-model="selectedDates"
      multiple="range"
      width="100%"
      title=""
      show-adjacent-months
      :display-date="formatHeaderDate"
      :allowed-dates="allowedDates"
      @update:modelValue="handleDateSelection"
    >
      <template v-slot:title></template>
      <template v-slot:header>
        <div class="v-date-picker-header">
          <div class="v-date-picker-header__content">
            <h6>{{ formatHeaderDate(selectedDates) }}</h6>
          </div>
        </div>
      </template>
    </v-date-picker>
    <v-alert v-if="errorMessage" type="error" class="mt-4">
      {{ errorMessage }}
    </v-alert>
  </v-card>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps, onMounted } from "vue";
import { useBookingsStore } from "@/stores/bookings";
import { formatDate } from "@/utils/dateFormatter";
import dayjs from "dayjs";

const emit = defineEmits(["update:dates"]);
const props = defineProps({
  allowedDates: {
    type: Array,
    required: true,
  },
  selectedRoom: {
    type: String,
    required: true,
  },
});

const bookingsStore = useBookingsStore();
const isLoading = ref(true);

const selectedDates = ref([]);
const errorMessage = ref("");

const formatHeaderDate = (dates) => {
  if (!dates || dates.length === 0) {
    return "Select Dates for Your Stay";
  }

  const minDate = dayjs(Math.min(...dates.map((d) => dayjs(d))));
  const maxDate = dayjs(Math.max(...dates.map((d) => dayjs(d))));

  const formattedMinDate = formatDate(minDate);
  const formattedMaxDate = formatDate(maxDate);

  const nights =
    maxDate.diff(minDate, "day") === 0 ? 1 : maxDate.diff(minDate, "day");
  const nightText = nights === 1 ? "night" : "nights";
  return `${formattedMinDate} - ${formattedMaxDate} (${nights} ${nightText})`;
};

const handleDateSelection = (newDates) => {
  if (newDates.length > 1) {
    const startDate = dayjs(newDates[0]);
    const endDate = dayjs(newDates[newDates.length - 1]);


    for (let d = startDate; d <= endDate; d = d.add(1, "day")) {
      if (!props.allowedDates.includes(d.format("YYYY-MM-DD"))) {
        errorMessage.value =
          "Your selected range includes unavailable dates. Please select a different range.";
        selectedDates.value = [];
        emit("update:dates", []);
        return;
      }
    }
  }

  errorMessage.value = "";
  selectedDates.value = newDates;
  emit("update:dates", newDates);
};

onMounted(async () => {
  try {
    await bookingsStore.fetchAvailableDates();
  } catch (error) {
    console.error("Error fetching available dates:", error);
    errorMessage.value = "Failed to load available dates. Please try again.";
  } finally {
    isLoading.value = false;
  }
});

watch(
  () => props.allowedDates,
  () => {
    // Clear selection when allowed dates change (e.g., when room selection changes)
    selectedDates.value = [];
    emit("update:dates", []);
    errorMessage.value = "";
  }
);
</script>

<style>
.v-picker-title {
  display: none !important;
}

/* Custom styles for blocked dates */
.v-date-picker-month__day .v-btn--disabled {
  background-color: #ffcccb !important; /* Light red background */
  opacity: 1 !important;
}

.v-date-picker-month__day .v-btn--disabled .v-btn__content {
  color: #808080 !important; /* Grey text */
}

/* Ensure blocked dates are not clickable */
.v-date-picker-month__day .v-btn--disabled {
  pointer-events: none;
}

/* Style for days between selected range */
.v-date-picker-month__day {
  position: relative;
}

div :nth-child(1 of div.v-date-picker-month__day--selected)::before{
  left: 0px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

div :nth-last-child(1 of div.v-date-picker-month__day--selected)::before {
  right: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.v-date-picker-month__day--selected::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -50%;
  right: -50%;
  background-color: rgb(235 245 254);
  z-index: 1;
}
.v-date-picker-month__day--selected .v-btn{
  z-index:2
}

/* Add a style for the skeleton loader to match the date picker height */
.v-skeleton-loader {
  max-width: 100%;
}
</style>

<template>
  <div>
    <v-card-subtitle class="mt-4 mb-2">{{ label }}</v-card-subtitle>
    <v-chip-group v-model="selectedRestrictions" column multiple>
      <v-chip
        v-for="restriction in dietaryRestrictions"
        :key="restriction.value"
        :value="restriction.value"
        filter
      >
        <v-icon start>{{ restriction.icon }}</v-icon>
        {{ restriction.label }}
      </v-chip>
      <v-chip
        value="other"
        filter
        @click="toggleOtherInput"
        :class="{ 'v-chip--selected': showOtherInput }"
      >
        <v-icon start>mdi-plus</v-icon>
        Other
      </v-chip>
    </v-chip-group>
    <v-expand-transition>
      <v-textarea
        v-if="showOtherInput"
        v-model="otherRestrictions"
        label="Other Dietary Restrictions"
        rows="2"
        class="mt-2"
      ></v-textarea>
    </v-expand-transition>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ selected: [], other: "" }),
  },
  label: {
    type: String,
    default: "Dietary Restrictions",
  },
});

const emit = defineEmits(["update:modelValue"]);

const dietaryRestrictions = [
  { label: "Vegetarian", value: "vegetarian", icon: "mdi-leaf" },
  { label: "Vegan", value: "vegan", icon: "mdi-sprout" },
  { label: "Gluten-Free", value: "gluten-free", icon: "mdi-barley-off" },
  { label: "Dairy-Free", value: "dairy-free", icon: "mdi-cow-off" },
  { label: "Nut Allergy", value: "nut-allergy", icon: "mdi-peanut-off" },
  {
    label: "Shellfish Allergy",
    value: "shellfish-allergy",
    icon: "mdi-fish-off",
  },
];

const selectedRestrictions = ref(props.modelValue.selected || []);
const otherRestrictions = ref(props.modelValue.other || "");
const showOtherInput = ref(!!props.modelValue.other);

const toggleOtherInput = () => {
  showOtherInput.value = !showOtherInput.value;
  if (!showOtherInput.value) {
    otherRestrictions.value = "";
    const index = selectedRestrictions.value.indexOf("other");
    if (index > -1) {
      selectedRestrictions.value.splice(index, 1);
    }
  } else {
    if (!selectedRestrictions.value.includes("other")) {
      selectedRestrictions.value.push("other");
    }
  }
};

watch([selectedRestrictions, otherRestrictions], () => {
  const restrictions = [
    ...selectedRestrictions.value.filter((r) => r !== "other"),
    ...(showOtherInput.value ? ["other"] : []),
  ];
  emit("update:modelValue", {
    selected: restrictions,
    other: otherRestrictions.value,
  });
});
</script>

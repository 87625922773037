import { defineStore } from "pinia";
import { supabase } from "@/lib/supabaseClient";
import { apiFetch } from "@/utils/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const useBookingsStore = defineStore("bookings", {
  state: () => ({
    bookings: [],
    availableDates: {
      red: [],
      green: [],
    },
    clubEvents: [],
    eventDetails: {}, // New state to store event details
    clubMenus: null,
  }),
  actions: {
    async fetchBookings() {
      const { data, error } = await supabase
        .from("bookings")
        .select("*")
        .order("start_date", { ascending: true });

      if (error) throw error;
      this.bookings = data;
    },
    async fetchAvailableDates() {
      try {
        const { availableDates } = await apiFetch("/api/get-available-dates");
        // console.log("availableDates", availableDates);
        this.availableDates = availableDates;
      } catch (error) {
        console.error("Error fetching available dates:", error);
        throw error;
      }
    },
    async cancelBooking(bookingId) {
      //   console.log("calling cancelBooking", bookingId);
      const { data, error } = await supabase
        .from("bookings")
        .update({ is_canceled: true })
        .eq("id", bookingId)
        .select();

      //   console.log("cancelBooking", data, error);

      if (error) throw error;

      // Update the local state
      const index = this.bookings.findIndex((b) => b.id === bookingId);
      if (index !== -1) {
        this.bookings[index] = { ...this.bookings[index], is_canceled: true };
      }
    },
    async fetchClubEvents(startDate, endDate) {
      try {
        const start = dayjs.tz(startDate, "America/New_York").startOf('day').toISOString();
        const end = dayjs.tz(endDate, "America/New_York").endOf('day').toISOString();

        const { data, error } = await supabase
          .from('club_events')
          .select('*')
          .gte('startDate', start)
          .lte('endDate', end)
          .order('startDate', { ascending: true });

        if (error) throw error;
        this.clubEvents = data;
      } catch (error) {
        console.error("Error fetching club events:", error);
        throw error;
      }
    },
    async fetchEventDetails(eventId) {
      if (this.eventDetails[eventId]) return; // If details already exist, don't fetch again

      try {
        const details = await apiFetch(`/api/getEventDetails?id=${eventId}`);
        this.eventDetails[eventId] = details;
      } catch (error) {
        console.error('Error fetching event details:', error);
        this.eventDetails[eventId] = { error: 'Failed to load event details' };
      }
    },

    clearEventDetails() {
      this.eventDetails = {};
    },
    isValidBooking(startDate, endDate, room) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const today = new Date();
      const twoFromNow = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 2
      );
      const nineMonthsFromNow = new Date(
        today.getFullYear(),
        today.getMonth() + 9,
        today.getDate()
      );

      if (start < twoFromNow) return false;
      if (end > nineMonthsFromNow) return false;

      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 12) return false;

      // Check if all dates in the range are available for the selected room
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        const dateString = d.toISOString().split("T")[0];
        if (!this.availableDates[room].includes(dateString)) {
          return false;
        }
      }

      return true;
    },
    getBookingById(id) {
      return this.bookings.find((booking) => booking.id === id);
    },
    async fetchClubMenus() {
      try {
        const { data, error } = await supabase
          .from('club_extras')
          .select('data')
          .eq('name', 'club_menus')
          .single();

        if (error) throw error;
        this.clubMenus = data.data;
      } catch (error) {
        console.error('Error fetching club menus:', error);
      }
    },
  },
  getters: {
    upcomingBookings: (state) => {
      const today = new Date();
      return state.bookings
        .filter((booking) => {
          const endDate = new Date(booking.end_date);
          return endDate >= today && !booking.is_canceled;
        })
        .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
    },
    canceledBookings: (state) => {
      return state.bookings
        .filter((booking) => booking.is_canceled)
        .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    },
    previousBookings: (state) => {
      const today = new Date();
      return state.bookings
        .filter((booking) => {
          const endDate = new Date(booking.end_date);
          return endDate < today && !booking.is_canceled;
        })
        .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
    },
    getClubEventsForBooking: (state) => (booking) => {
      if (!booking) return [];
      return state.clubEvents.filter(event => 
        new Date(event.startDate) >= dayjs(booking.start_date).startOf('day') &&
        new Date(event.endDate) <= dayjs(booking.end_date).endOf('day')
      );
    },
    getEventDetails: (state) => (eventId) => {
      return state.eventDetails[eventId] || null;
    },
  },
});

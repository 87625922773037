import { defineStore } from "pinia";
import { apiFetch } from "@/utils/api";

export const usePlusOnesStore = defineStore("plusOnes", {
  state: () => ({
    historicalPlusOnes: [],
  }),
  actions: {
    async fetchHistoricalPlusOnes() {
      try {
        const { data } = await apiFetch("/api/getHistoricalPlusOnes");
        this.historicalPlusOnes = data;
      } catch (error) {
        console.error("Error fetching historical plus ones:", error);
      }
    },
  },
});
